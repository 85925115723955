import { t } from "i18next";
import { currencySymbol } from "tiers/currency";
import { Consts } from "vars";
/** Beacon wrapper */
export var Bcn;
(function (Bcn) {
    function init() {
        (function (e, t, n) { function a() { var e = t.getElementsByTagName("script")[0], n = t.createElement("script"); n.type = "text/javascript", n.async = !0, n.src = "https://beacon-v2.helpscout.net", e.parentNode.insertBefore(n, e); } if (e.Beacon = n = function (t, n, a) { e.Beacon.readyQueue.push({ method: t, options: n, data: a }); }, n.readyQueue = [], "complete" === t.readyState)
            return a(); e.attachEvent ? e.attachEvent("onload", a) : e.addEventListener("load", a, !1); })(window, document, window.Beacon || function () { });
    }
    Bcn.init = init;
    function load() {
        Beacon('init', '2ee18501-cd54-44e1-8cac-b39bdfac6d17');
    }
    Bcn.load = load;
    function article(id, style) {
        Beacon('article', id, { type: style });
    }
    Bcn.article = article;
    function setSessionData(provider, user, tier) {
        // First reset session data.
        unsetSessionData();
        var data = {
            dbId: user.id,
            adminLink: "https://chemixapp.appspot.com/admin/lookup?emailOrId=" + user.id,
            tier: tier !== null && tier !== void 0 ? tier : "basic",
            oauthProvider: provider,
            avatar: user.avatarUrl,
            version: Consts.version,
        };
        if (user.email)
            data["email"] = user.email;
        if (provider == "twitter" && user.handle)
            data["twitterHandle"] = user.handle;
        if (user.name)
            data["name"] = user.name;
        Beacon('session-data', data);
        // Identify.
        if (user.email) {
            Beacon('identify', { email: user.email });
        }
    }
    Bcn.setSessionData = setSessionData;
    function unsetSessionData() {
        Beacon('reset');
        Beacon('logout');
    }
    Bcn.unsetSessionData = unsetSessionData;
    function open() {
        Beacon('open');
    }
    Bcn.open = open;
    function ask() {
        Beacon('navigate', '/ask/');
        Beacon('open');
    }
    Bcn.ask = ask;
    function openSchoolEnquiry(numberOfStudents, price, currency, period) {
        Beacon('prefill', {
            subject: t("beacon.upgrade.subject"),
            text: t("beacon.upgrade.body", { currency: currencySymbol(currency), price: price, period: t(period), students: numberOfStudents })
        });
        Beacon('navigate', '/ask/message/');
        Beacon('open');
    }
    Bcn.openSchoolEnquiry = openSchoolEnquiry;
    function openRequestCurrencyFix() {
        Beacon('prefill', {
            subject: 'Problem with upgrading an account',
            text: 'Please investigate currency conflict issue with upgrading my account.',
        });
        Beacon('navigate', '/ask/message/');
        Beacon('open');
    }
    Bcn.openRequestCurrencyFix = openRequestCurrencyFix;
    function openFeatureSuggestion() {
        Beacon('prefill', {
            subject: t("beacon.suggest.subject"),
            text: t("beacon.suggest.body"),
        });
        Beacon('navigate', '/ask/message/');
        Beacon('open');
    }
    Bcn.openFeatureSuggestion = openFeatureSuggestion;
    function reportTranslationBug() {
        Beacon('prefill', {
            subject: t("beacon.translation_bug.subject"),
            text: t("beacon.translation_bug.body"),
        });
        Beacon('navigate', '/ask/message/');
        Beacon('open');
    }
    Bcn.reportTranslationBug = reportTranslationBug;
})(Bcn || (Bcn = {}));
window["Bcn"] = Bcn;
/** Article IDs */
export var Articles;
(function (Articles) {
    Articles.NEW = "5fb54c0b46e0fb0017fcd333";
    Articles.TOS = "5fb565c04cedfd00165b1a28";
    Articles.ABOUT = "5fb524eacff47e00160bb1a7";
    Articles.SCREEN_TOO_SMALL = "5fb5673acff47e0017d33160";
    Articles.CHEMTEXT = "5fb5690a4cedfd00165b1a34";
    Articles.IMAGE_FORMATS = "5fb56a42cff47e0017d33173";
    Articles.IMAGE_QUALITY = "5fb56e064cedfd001610e2f9";
    Articles.SUBSCRIPTIONS = "5fb564154cedfd001610e2b5";
    Articles.CHRISTMAS_PROMO = "5fbafd3a46e0fb0017fce7c8";
    Articles.LICENSE = "5fb56603cff47e00160bb3fe";
    Articles.POURING = "60d6ecca9e87cb3d01247700";
    Articles.MENISCUS = "60d6f1018556b07a28849c62";
})(Articles || (Articles = {}));
